export const environment = {
  production: true,
  keycloak: {
    issuer: 'https://dev-keycloak21.lptech.vn/',
    realm: 'dev',
    client: 'frontend',
  },
  client_code: 'LPTECH',
  endpoint_url: 'https://dev-hcm-api.lptech.vn/v1',
  apmServerUrl: 'https://dev-apm.lptech.vn',
};
//Update
