import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataTable } from '@cores/models/data-table.model';
import { BaseService } from '@cores/services/base.service';
import { removeParamNullOrUndefined, mapDataTable } from '@cores/utils/functions';
import { environment } from '@env';
import * as saveAs from 'file-saver';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService extends BaseService {
  constructor(http: HttpClient) {
    super(http, `${environment.endpoint_url}/candidate-attachment`);
  }

  deleteDoc(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.endpoint_url}/candidate-attachment/${id}`);
  }

  getFileList(code: string): Observable<any> {
    return this.http.get(`${environment.endpoint_url}/candidate-attachment?candidateCode=${code}`);
  }

  downloadDocument(fileName: string, doc: string, params?: any): Observable<boolean> {
    return this.http
      .get(`${environment.endpoint_url}/attachments/download/${doc}`, {
        params: { ...params },
        responseType: 'arraybuffer',
      })
      .pipe(
        map(res => {
          saveAs(
            new Blob([res], {
              type: 'application/octet-stream',
            }),
            fileName
          );
          return true;
        })
      );
  }

  importFile(files: File[], body?: any): Observable<any> {
    const formData: FormData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('dto', JSON.stringify(body))
    return this.http.post(`${environment.endpoint_url}/candidate-attachment`, formData);
  }
}
