import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationMessageService } from '@cores/services/message.service';
import { Attachment } from '@models/attachment.model';
import { AttachmentService } from '@features/candidate/candidate-information/services/attachment.service';
import { popResponseError } from '@cores/utils/functions';
import { LoadingService } from '@cores/services/loading.service';

@Component({
  selector: 'lp-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
})
export class UploadFilesComponent {
  @Input() screenType?: number = 0;
  @Input() showLabel?: boolean = true;
  @Input() importedFiles: File[] = [];
  @Input() listAttachment: Attachment[] = [];
  @Input() maxFileSize: number = 10240; // 10MB
  @Input() accept: string = '';
  @Input() multiple: boolean = false;
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private messageService: NotificationMessageService,
    private attachmentService: AttachmentService,
    private loadingService: LoadingService
  ) {}
  onFileChange(event: any): void {
    const files: File[] = event.target.files;
    if (this.multiple) {
      for (let element of files) {
        if (!this.checkFile(element)) {
          this.importedFiles.push(element);
        }
      }
    } else {
      this.importedFiles[0] = files[0];
    }
    if (this.importedFiles.length) {
      const totalSize = Array.from(this.importedFiles).reduce((accumulator, file) => accumulator + file.size, 0);
      if (totalSize > this.maxFileSize * 1024) {
        this.messageService.warn(`File tải lên quá lớn`);
      }
    }
    event.target.value = null;
  }
  checkFile(file: File): boolean {
    return this.importedFiles.some(element => element.name === file.name && element.size === file.size);
  }

  deleteAttachmentFile(index: number, files: Attachment[]) {
    this.remove.emit(files[index]);
    files.splice(index, 1);
  }
  deleteImportedFiles(index: number, files: File[]) {
    files.splice(index, 1);
  }
  downAttachmentFile(attachment: Attachment) {
    this.loadingService.start();
    this.attachmentService
      .downloadDocument(attachment?.fileName!, (attachment?.fileKey ? attachment?.fileKey : attachment?.fileScan)!)
      .subscribe({
        next: () => {
          this.loadingService.complete();
        },
        error: err => {
          this.messageService.error(popResponseError(err));
          this.loadingService.complete();
        },
      });
  }
}
