<div *ngIf="screenType !== 0" class="pt-3 pl-1 col-12">
  <span *ngIf="showLabel" class="doc-header">{{  'PROJECT.SECTION-HEADER.FILE-DOCUMENT' | translate }}</span>
  <div *ngIf="listAttachment?.length" class="tag-width">
    <p-tag
      *ngFor="let itemAttachment of listAttachment; let i = index"
      class="mr-2 pointer"
      styleClass="sky"
      value="{{ itemAttachment.fileName }}"
      (click)="downAttachmentFile(itemAttachment)"
    >
      <em *ngIf="screenType !== 2" class="ml-2 lp-white-cancel" (click)="deleteAttachmentFile(i, listAttachment);$event.stopPropagation()"></em>
    </p-tag>
  </div>
  <div *ngIf="!listAttachment?.length" class="tag-width">
    ---
  </div>
</div>

<div *ngIf="screenType !== 2" class="pt-3 pl-1 col-12">
  <span  class="doc-header">{{ 'ALLOWANCE.SECTION-HEADER.UPLOAD-FILE' | translate }}</span>
  <input type="file" #inputFile [multiple]="multiple" style="display: none" accept="{{accept}}" (change)="onFileChange($event)" />
  <div  class="flex flex-column align-items-start">
    <div (click)="inputFile.click()" class="upload-file-container mb-3">
      <div class="upload-file-label"></div>
      <div class="upload-file-content">
          <span class="upload-btn-container">
            <em class="lp-upload-cloud"></em>
            <span>{{ 'BUTTON.CHOOSE-FILE' | translate }}</span>
          </span>
      </div>
    </div>
    <div class="tag-width">
      <p-tag
        *ngFor="let file of importedFiles; let i = index"
        class="mr-2"
        styleClass="sky"
        value="{{ file.name }}"
      >
        <em  class="ml-2 lp-white-cancel" (click)="deleteImportedFiles(i, importedFiles);$event.stopPropagation()"></em>
      </p-tag>
    </div>
  </div>
</div>
